import React from "react";

export const MtsBank = () => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>МТС Банк</title>
            <rect width="44" height="44" fill="#FF0032"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.24835 15.125C6.90765 15.125 7.69137 15.7488 8.34377 16.7976C9.01442 17.8838 9.41629 19.2284 9.41629 20.3961C9.41629 22.1055 8.43634 23.8294 6.24835 23.8294C4.05822 23.8294 3.08032 22.1055 3.08032 20.3961C3.08032 19.2284 3.48247 17.8838 4.15639 16.7976C4.80301 15.7488 5.58711 15.125 6.24835 15.125ZM30.1668 15.5913V17.6897H27.9491V23.3632H25.5732V17.6897H23.3555L23.3556 15.5913H30.1668ZM38.7203 15.5913V17.6897H35.2353C33.7538 17.6897 32.7792 18.2095 32.7792 19.4773C32.7792 20.745 33.7538 21.2648 35.2353 21.2648H38.7203V23.3632H35.2355C32.0335 23.3632 30.3241 21.801 30.3241 19.4773C30.3241 17.1535 32.0335 15.5913 35.2355 15.5913H38.7203ZM15.9899 15.5913L17.2565 20.2302L18.5232 15.5913H22.7219V23.3632H20.3459V17.0376L18.6186 23.3632H15.8944L14.1682 17.0416V23.3632H11.7923V15.5913H15.9899Z"
                  fill="white"/>
            <path
                d="M19.3 30.965C19.9125 30.965 20.3678 30.8132 20.6661 30.5097C20.9643 30.2062 21.1134 29.784 21.1134 29.2433C21.1134 28.728 20.9643 28.3314 20.6661 28.0533C20.3678 27.7753 19.9125 27.6363 19.3 27.6363H17.8381V26.9476H20.722V25.685H16.2803V30.965H19.3ZM19.0044 29.7789H17.8381V28.7459H19.0044C19.3187 28.7459 19.4758 28.8887 19.4758 29.1744V29.3657C19.4758 29.4983 19.4345 29.6004 19.3519 29.6718C19.2694 29.7432 19.1536 29.7789 19.0044 29.7789ZM22.807 30.965L23.1186 29.9549H25.0678L25.3873 30.965H27.0729L25.3714 25.685H23.0547L21.3531 30.965H22.807ZM24.7083 28.8071H23.4781L24.0932 26.8558L24.7083 28.8071ZM29.2299 30.965V28.9219H31.0113V30.965H32.609V25.685H31.0113V27.621H29.2299V25.685H27.6321V30.965H29.2299ZM35.1654 30.965V28.9219H35.3971L36.875 30.965H38.7203L36.7232 28.2026L38.5606 25.685H36.875L35.445 27.621H35.1654V25.685H33.5677V30.965H35.1654Z"
                fill="white"/>
        </svg>
    )
}
