import { hiddenProducts, hiddenSegments } from '../../../constants/products';
import { ChildSegment } from '../../../models/segment';

const mainCode = 'main';
const otherCode = 'other';

export const hideProducts = (childSegments?: ChildSegment[]) => {
    return childSegments?.map(childSegment => {
        const filteredProducts = childSegment.products.filter(product => !hiddenProducts.includes(product.code));
        return {
            ...childSegment,
            products: filteredProducts,
        };
    });
};

export const hideSegments = (childSegments?: ChildSegment[]) => {
    return childSegments?.filter((segment) => !hiddenSegments.includes(segment.code!));
};

export const getPartnersColumns = (columnsCount: number, childSegments?: ChildSegment[]) => {
    const columns: ChildSegment[][] = Array(columnsCount).fill('').map(() => []);

    if (!childSegments?.length) {
        return columns;
    }

    const products = [...childSegments[0].products];
    const productsLen = products.length;
    const eventCount = productsLen % columnsCount;
    const remainder = productsLen > columnsCount ? products.splice(0, eventCount) : [];
    const columnSize = Math.ceil(productsLen / columnsCount);

    columns.forEach((column, index) => {
        const customSegment = { ...childSegments[0] };
        customSegment.id = index;
        column.push(customSegment);
        column[0].products = products.splice(0, columnSize);
    });
    columns[0][0].products.push(...remainder);

    return columns;
};

const findChildSegmentByCode = (childSegments: ChildSegment[], code: string) => {
    return childSegments.find(p => p.code === code);
};

export const splitChildSegments = (columnsCount: number, _childSegments?: ChildSegment[]) => {
    const columns: ChildSegment[][] = Array(columnsCount).fill('').map(() => []);
    const columnSizes: number[] = Array(columnsCount).fill(0);
    if (!_childSegments?.length) {
        return columns;
    }
    let childSegments = [..._childSegments];

    childSegments?.sort((a, b) => b.products.length - a.products.length);

    const main = findChildSegmentByCode(childSegments, mainCode);
    const other = findChildSegmentByCode(childSegments, otherCode);

    childSegments = childSegments.filter(childSegment => {
        return childSegment.code !== mainCode && childSegment.code !== otherCode;
    });

    if (main) {
        columns[0].push({ ...main });
        columnSizes[0] += main.products.length + 1;
    }

    childSegments?.forEach(childSegment => {
        const smallestColumn = columnSizes.indexOf(Math.min(...columnSizes));
        columns[smallestColumn].push({ ...childSegment });
        columnSizes[smallestColumn] += childSegment.products.length + 1; // Длина массива объекта + название объекта
    });

    if (other) {
        if (columnsCount === 1 && other) {
            columns[0].push({ ...other });
        } else {
            columnSizes.find((columnSize, columnIndex) => {
                if (columnSize === 0 || (columnIndex === columnsCount - 1)) {
                    columns[columnIndex].push({ ...other });
                    columnSizes[columnIndex] += other.products.length + 1;

                    return true;
                }

                return false;
            });
        }
    }

    return columns;
};
