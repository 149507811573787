import React from 'react';

interface AvtosekretarIconProps {
    title: string;
    size: number;
}

export const AvtosekretarIcon: React.FC<AvtosekretarIconProps> = ({ title, size }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 130 130"
      >
        <title>{title}</title>
        <path fill="#FF0032" d="M0 0h130v130H0z" />
        <path
          fill="#fff"
          d="M83.43 18v5.204h11.16v20.358l.002.336h6.248V23.204H112V18H83.43ZM43.237 18.078 35.074 35.1l-8.162-17.023H18v25.74h6.248V25.396l7.978 15.563h5.697l7.98-15.563v18.422h6.248v-25.74h-8.914ZM105.736 103.876a4.275 4.275 0 0 1-1.224 2.51 5.219 5.219 0 0 1-2.498 1.266 19.666 19.666 0 0 1-4.136.376c-1.478.027-2.95-.19-4.358-.641a5.432 5.432 0 0 1-2.828-2.142c-.681-1.013-1.017-2.475-1.017-4.335v-2.637c0-1.873.343-3.335 1.017-4.348a5.432 5.432 0 0 1 2.821-2.14 13.482 13.482 0 0 1 4.358-.64c1.388-.022 2.775.105 4.136.376a5.225 5.225 0 0 1 2.498 1.266 4.275 4.275 0 0 1 1.224 2.509h6.253a9.151 9.151 0 0 0-2.142-5.382 9.779 9.779 0 0 0-4.696-2.879 26.338 26.338 0 0 0-7.268-.873c-2.996 0-5.594.433-7.722 1.286a9.872 9.872 0 0 0-4.996 4.292c-1.102 1.9-1.681 4.437-1.72 7.546v.584c.039 3.11.618 5.647 1.72 7.547a9.873 9.873 0 0 0 4.996 4.291c2.13.853 4.73 1.287 7.722 1.287a26.293 26.293 0 0 0 7.268-.874 9.749 9.749 0 0 0 4.696-2.879 9.147 9.147 0 0 0 2.142-5.382l-6.246.016Z"
        />
      </svg>
    );
};
