export const hiddenProducts = [
    'lichnii_kabinet',
    'mts_ab_testing',
    'mts_shop',
    'mts_smart_home',
    'appbazar',
    'launcher',
    'membrana_kids',
    'vdome_b2c',
    'starsup',
    'rushbe',
    'mts_travel',
    '_mts_loncher_junior',
    'mts_junior',
    'mts_strahovanie',
    'urent',
    'invetitsii',
    'live',
];

export const hiddenSegments = [
    'mts_avtosekretar',
];
