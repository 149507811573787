import React from 'react';

const LogoDefault = ({title = "Logo default", size}: { title?: string, size: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <title>{title}</title>
            <defs>
                <path id="text-logo-default.svg" fill="#fff"
                      d="M15.4 3.32v.96h2.06V8.1h1.16V4.28h2.06v-.96H15.4Zm-7.42.02-1.5 3.14-1.51-3.14H3.32v4.75h1.16v-3.4l1.47 2.87H7L8.47 4.7v3.4h1.16V3.34H7.98Zm11.54 15.84a.79.79 0 0 1-.23.46.96.96 0 0 1-.46.23 3.64 3.64 0 0 1-1.56-.04 1 1 0 0 1-.53-.4 1.44 1.44 0 0 1-.18-.8v-.49c0-.34.06-.61.18-.8a1 1 0 0 1 .52-.4 3.62 3.62 0 0 1 1.57-.05c.17.04.33.12.46.24s.2.29.23.46h1.15a1.69 1.69 0 0 0-.4-.99 1.8 1.8 0 0 0-.86-.53 4.86 4.86 0 0 0-2.77.07c-.38.15-.71.43-.92.8-.2.35-.3.82-.32 1.39v.05h.07-.07v.06c.01.57.12 1.04.32 1.4.21.35.54.63.92.78a4.84 4.84 0 0 0 2.77.08c.34-.1.64-.28.87-.53.23-.28.37-.63.4-1h-1.16Z"></path>
            </defs>
            <mask id="background-logo-default.svg" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
                <path fill="#fff" fillRule="evenodd"
                      d="M7.44 0h9.13c2.58 0 3.52.27 4.46.77a5.3 5.3 0 0 1 2.2 2.2c.5.94.77 1.88.77 4.46v9.14c0 2.58-.27 3.52-.77 4.46a5.28 5.28 0 0 1-2.2 2.2c-.94.5-1.88.77-4.46.77H7.44c-2.59 0-3.53-.27-4.47-.77a5.28 5.28 0 0 1-2.2-2.2C.28 20.1 0 19.15 0 16.57V7.44C0 4.85.27 3.9.77 2.97a5.27 5.27 0 0 1 2.2-2.2C3.9.27 4.85 0 7.44 0Z"
                      clipRule="evenodd"></path>
            </mask>
            <path fill="#BBC1C7" d="M0 0h24v24H0z" mask="url(#background-logo-default.svg)"></path>
            <use href="#text-logo-default.svg"></use>
        </svg>
    );
};

export default LogoDefault;
