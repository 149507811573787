import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './child-segments.styles';
import {
    getPartnersColumns,
    hideProducts,
    hideSegments,
    splitChildSegments,
} from './child-segments.utils';
import { useDevice } from '../../../context/device-context';
import { useIsApp } from '../../../custom-hooks/use-is-app';
import { SegmentsEnum } from '../../../models/enums/segments.enum';
import { selectChildSegments, selectCurrentSegmentCode } from '../../../redux/segments/segments-selectors';
import { ChildSegmentGroups } from '../child-segment-groups/child-segment-groups';
import { SegmentProducts } from '../segment-products/segment-products';

export const ChildSegments = () => {
    const childSegments = useSelector(selectChildSegments);
    const currentSegmentCode = useSelector(selectCurrentSegmentCode);
    const { isMobile } = useDevice();
    const { isTablet } = useDevice();
    const { isMyMtsApp } = useIsApp();
    const isPartners = currentSegmentCode === SegmentsEnum.partners;
    const filteredProductsOfSegments = isMyMtsApp ? hideProducts(childSegments) : childSegments;
    // @todo убрать фильтрацию дочерних сегментов после реализации суперпродукта (продукт, содержащий другие продукты) на CMS и BE справки
    const filteredSegments = hideSegments(filteredProductsOfSegments);

    let columnsCount = 3;
    if (isMobile) {
        columnsCount = 1;
    } else if (isTablet) {
        columnsCount = 2;
    }

    const columns = useMemo(() => {
        if (isPartners) {
            return getPartnersColumns(columnsCount, filteredSegments);
        }
        return splitChildSegments(columnsCount, filteredSegments);
    }, [childSegments, columnsCount, isMyMtsApp]);

    return (
        <div>
            <ChildSegmentGroups/>
            <Styled.ChildSegmentsBlock>
                {columns.map((column, index) => (
                    <div key={index}>
                        {column.map(childSegment => {
                            if (childSegment.products.length > 0) {
                                return (
                                    <SegmentProducts childSegment={childSegment} key={childSegment.id}/>
                                );
                            }
                        })}
                    </div>
                ))}
            </Styled.ChildSegmentsBlock>
        </div>
    );
};
