import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import * as Styled from './child-segment-link.styles';
import { useAppRouter } from '../../../custom-hooks/use-app-router';
import { makeLastSpaceNonBreaking } from '../../../helpers/make-last-space-non-breaking.helper';
import { MainPageAnalytics } from '../../../helpers/web-analytics/main-page-analytics';
import { AvtosekretarIcon } from '../../svg-components/main/avtosekretar-icon';
import { LoaderCircle } from '../loader-circle/loader-circle';

export const ChildSegmentLinkForAvtosekretar: React.FC = () => {
    const router = useAppRouter();
    const [isLoading, setLoading] = useState(false);
    const themeContext = useContext(ThemeContext);

    const linkClickHandler = () => {
        setLoading(true);
        MainPageAnalytics.productNameClick('mts_avtosekretar');
        router.push('/child_segment/mts_avtosekretar');
    };

    const contentComponent = (
        <>
            <Styled.IconWrapper>
                <AvtosekretarIcon size={44} title="Автосекретарь"/>
            </Styled.IconWrapper>
            <Styled.LinkTextBlock>
                <Styled.LinkTitle>{makeLastSpaceNonBreaking('Автосекретарь')}</Styled.LinkTitle>
                <Styled.LinkDescription>{'Единый виртуальный многоканальный телефонный номер'}</Styled.LinkDescription>
            </Styled.LinkTextBlock>
        </>
    );

    return (
        <Styled.Container>
            <Styled.Item
                onClick={linkClickHandler}
                isLoading={isLoading}
            >
                {contentComponent}
            </Styled.Item>
            {isLoading && <LoaderCircle type={themeContext.loaderColor} centered />}
        </Styled.Container>
    );
};
